<template>
  <el-col class="text-container" :span="24" style="position: absolute;top: 0%;left: 0%">
    <transition class="" name="slide" mode="out-in">
      <p class="text" :key="text.id">{{text.val}}</p>
    </transition>
  </el-col>
</template>

<script>
export default {
  name: "announcement",
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      count: 0, // 当前索引
      intervalId: null, //定时器ID
      playTime: 2000, // 定时器执行间隔
    }
  },
  computed: {
    text() {
      return {
        id: this.count,
        val: this.dataList[this.count]
      }
    }
  },
  created() {
    this.intervalId = setInterval(() => {
      // 定义定时器
      this.getText();
    }, this.playTime)
  },
  methods: {
    getText() {
      const len = this.dataList.length; // 获取数组长度
      if(len === 0) {
        return; // 当数组为空时，直接返回
      }
      if(this.count === len - 1) { // 当前为最后一个时
        this.count = 0;// 从第一个开始
        return;
      }
      this.count++;
    }
  },
  destroyed() {
    clearInterval(this.intervalId); // 清除定时器
  }
}
</script>

<style scoped>
  .text {
    color: #FFFFFF;
    line-height: 14px;
    font-size: 14px;
    font-weight: 400;
  }

  .slide-enter-active, .slide-leave-active {
    transition: all 1s;
  }

  .slide-enter {
    /*animation: 1s showNone linear infinite normal;*/
    animation-duration: 1s;
    animation-name: showNone;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes showNone {
    from {transform: none}
    to {transform: translate3d(0, 15px, 0)}
  }

  .slide-leave-to {
    /*animation: 1s noneShow linear infinite normal ;*/
    animation-duration: 1s;
    animation-name: noneShow;
    animation-direction: reverse;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes noneShow {
    from {transform: translate3d(0, -15px, 0)}
    to {transform: none}
  }
</style>
