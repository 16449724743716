<template>
  <div>
  <el-row ref="rowhome">
    <el-col :xs="24" :sm="0" :md="0" :lg="0" :xl="0">
      <el-col class="banner">
        <el-col class="banner-des banner-text">
          <el-col>SaaS产品购买及服务平台</el-col>
        </el-col>
        <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="switch-btn left">
          <img src="@/img/home/ic_left.svg" @click="arrowClick('prev')">
        </el-col>
        <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="switch-btn right">
          <img src="@/img/home/ic_right.svg" @click="arrowClick('next')">
        </el-col>
        <el-col class="banner-carousel">

          <el-col  :xs="24" :sm="0" :md="0" :lg="0" :xl="0" >
            <el-carousel :autoplay="false" type="card" indicator-position="outside" arrow="never" class="carousel" ref="carousel" direction="vertical">
              <el-carousel-item>

                <img src="@/img/home/p_wendang.png" class="carousel-img">
                <el-button class="banner-btn" @click="productDoc">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
                <el-col class="product-title">
                  文档安全管理系统
                </el-col>
              </el-carousel-item>
              <el-carousel-item>

                <img src="@/img/home/bigFile.png" class="carousel-img">
                <el-button class="banner-btn" @click="productMail">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
                <el-col class="product-title">
                  大附件邮件助手
                </el-col>
              </el-carousel-item>
              <el-carousel-item>

                <img src="@/img/home/cloudManage.png" class="carousel-img">
                <el-button class="banner-btn" @click="productCloud">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
                <el-col class="product-title">
                  多云管理平台
                </el-col>
              </el-carousel-item>
                <el-carousel-item>

                    <img src="@/img/home/bpm.png" class="carousel-img">
                    <el-button class="banner-btn" @click="productBPM">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
                    <el-col class="product-title">
                        eBPM审批管理系统
                    </el-col>
                </el-carousel-item>
                <el-carousel-item>
                    <img src="@/img/home/itsm.png" class="carousel-img">
                    <el-button class="banner-btn" @click="productITSM">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
                    <el-col class="product-title">
                        eITSM工单管理系统
                    </el-col>
                </el-carousel-item>
            </el-carousel>
          </el-col>

        </el-col>
        <el-col class="banner-notice banner-text">
          <el-col :xs="3" :sm="7" :md="7" :lg="7" :xl="7" style="height: 1px"></el-col>
          <el-col :xs="18" :sm="10" :md="10" :lg="10" :xl="10" class="notice" style="position: relative;">
<!--            <el-col :xs="5" :sm="2" :md="2" :lg="2" :xl="2" class="notice-icon"></el-col>-->
<!--            <announcement :dataList="list"></announcement>-->
              <p class="home-title">伊登文档安全管理系统，高效、便捷、安全、协同、合规，专注文档管控和内容管理！为企业提供统一文档办公平台，为企业业务系统提供文档内容服务。</p>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="new-information">
        <el-col class="title">新闻动态</el-col>
        <el-col class="new-list" :xl="24" :lg="10">
          <el-col :span="4" style="height: 1px"></el-col>
          <el-col class="new-item" :xl="6" :lg="24">
            <el-col class="new-img news">
              <img src="@/img/home/news_img.png"/>
            </el-col>
            <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
            <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
            <el-col class="new-link">
              <span class="new-detail">查看详情</span>
              <img src="@/img/common/right.png"/>
            </el-col>
          </el-col>
          <el-col class="new-item" :xl="6" :lg="24">
            <el-col class="new-img news">
              <img src="@/img/home/news_img.png"/>
            </el-col>
            <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
            <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
            <el-col class="new-link">
              <span class="new-detail">查看详情</span>
              <img src="@/img/common/right.png"/>
            </el-col>
          </el-col>
          <el-col class="new-item" :xl="6" :lg="24">
            <el-col class="new-img news">
              <img src="@/img/home/news_img.png"/>
            </el-col>
            <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
            <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
            <el-col class="new-link">
              <span class="new-detail">查看详情</span>
              <img src="@/img/common/right.png"/>
            </el-col>
          </el-col>
        </el-col>
      </el-col>
<!--      <el-col class="partner">-->
<!--        <el-col class="title">伊登合作伙伴</el-col>-->
<!--        <el-col class="partner-list" :xl="16" :lg="6" :md="6" :sm="6" :xs="4">-->
<!--          &lt;!&ndash;        <el-col :span="4" style="height: 1px"></el-col>&ndash;&gt;-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_huawei.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_ali.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_tencent.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_eqianbao.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_aws.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_google_cloud.png">-->
<!--          </el-col>-->
<!--          <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="7">-->
<!--            <img src="@/img/home/partner_azure.png">-->
<!--          </el-col>-->
<!--        </el-col>-->
<!--      </el-col>-->
    </el-col>
    <el-col :xs="0" :sm="24" :md="24" :lg="24" :xl="24">
    <el-col class="banner">
      <el-col class="banner-des banner-text">
        <el-col>SaaS产品购买及服务平台</el-col>
      </el-col>

      <el-col class="banner-carousel">
        <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="switch-btn left">
          <img src="@/img/home/ic_left.svg" @click="arrowClick('prev')">
        </el-col>
        <el-col  :xs="0" :sm="16" :md="16" :lg="16" :xl="16" >
          <el-carousel :autoplay="false" type="card" @change="carouselChange" indicator-position="outside" arrow="never" class="carousel" ref="carousel" direction="horizontal">
            <el-carousel-item>
              <el-col class="product-title">
                文档安全管理系统
              </el-col>
              <img src="@/img/home/p_wendang.png" class="carousel-img">
              <el-button class="banner-btn" @click="productDoc">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
            </el-carousel-item>
            <el-carousel-item>
              <el-col class="product-title">
                大附件邮件助手
              </el-col>
              <img src="@/img/home/bigFile.png" class="carousel-img">
              <el-button class="banner-btn" @click="productMail">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
            </el-carousel-item>
            <el-carousel-item>
              <el-col class="product-title">
                多云管理平台
              </el-col>
              <img src="@/img/home/cloudManage.png" class="carousel-img">
              <el-button class="banner-btn" @click="productCloud">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
            </el-carousel-item>
              <el-carousel-item>
                  <el-col class="product-title">
                      eBPM审批管理系统
                  </el-col>
                  <img src="@/img/home/bpm.png" class="carousel-img">
                  <el-button class="banner-btn" @click="productBPM">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
              </el-carousel-item>
              <el-carousel-item>
                  <el-col class="product-title">
                      eITSM工单管理系统
                  </el-col>
                  <img src="@/img/home/itsm.png" class="carousel-img">
                  <el-button class="banner-btn" @click="productITSM">立即了解<img src="@/img/common/right_arrow_white.svg" class="banner-right-arrow"></el-button>
              </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :xs="0" :sm="4" :md="4" :lg="4" :xl="4" class="switch-btn right">
          <img src="@/img/home/ic_right.svg" @click="arrowClick('next')">
        </el-col>
      </el-col>
      <el-col class="banner-notice banner-text">
        <el-col :xs="3" :sm="7" :md="7" :lg="7" :xl="7" style="height: 1px"></el-col>
        <el-col :xs="18" :sm="10" :md="10" :lg="10" :xl="10" class="notice" style="position: relative;">
<!--          <el-col :xs="5" :sm="2" :md="2" :lg="2" :xl="2" class="notice-icon"></el-col>-->
<!--          <announcement :dataList="list"></announcement>-->
<!--            <p class="home-title">伊登文档安全管理系统，高效、便捷、安全、协同、合规，专注文档管控和内容管理！为企业提供统一文档办公平台，为企业业务系统提供文档内容服务。</p>-->
                <p class="home-title">{{ bannerText }}</p>
        </el-col>
      </el-col>
    </el-col>
    <el-col class="new-information">
      <el-col class="title">新闻动态</el-col>
      <el-col class="new-list" :xl="24" :lg="10">
        <el-col :span="4" style="height: 1px"></el-col>
        <el-col class="new-item" :xl="6" :lg="24">
          <el-col class="new-img news">
            <img src="@/img/home/news_img.png"/>
          </el-col>
          <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
          <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
          <el-col class="new-link">
            <span class="new-detail">查看详情</span>
            <img src="@/img/common/right.png"/>
          </el-col>
        </el-col>
        <el-col class="new-item" :xl="6" :lg="24">
          <el-col class="new-img news">
            <img src="@/img/home/news_img.png"/>
          </el-col>
          <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
          <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
          <el-col class="new-link">
            <span class="new-detail">查看详情</span>
            <img src="@/img/common/right.png"/>
          </el-col>
        </el-col>
        <el-col class="new-item" :xl="6" :lg="24">
          <el-col class="new-img news">
            <img src="@/img/home/news_img.png"/>
          </el-col>
          <el-col class="new-title news">伊登云文档移动端，助理企业高效移动办公！</el-col>
          <el-col class="new-des news">数据和沟通没有变，连接数据和人的方式却一直在变，当人与人的连接已经转换到移动端，你的工作文件却1ad撒东你的工作文件却1ad撒东你的工作文件却1ad撒东</el-col>
          <el-col class="new-link">
            <span class="new-detail">查看详情</span>
            <img src="@/img/common/right.png"/>
          </el-col>
        </el-col>
      </el-col>
    </el-col>
<!--    <el-col class="partner">-->
<!--      <el-col class="title">伊登合作伙伴</el-col>-->
<!--      <el-col class="partner-list" :xl="16" :lg="6" :md="6" :sm="6" :xs="4">-->
<!--        &lt;!&ndash;        <el-col :span="4" style="height: 1px"></el-col>&ndash;&gt;-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_huawei.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_ali.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_tencent.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_eqianbao.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_aws.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_google_cloud.png">-->
<!--        </el-col>-->
<!--        <el-col class="partner-item" :xl="6" :lg="12" :md="12" :sm="12" :xs="8">-->
<!--          <img src="@/img/home/partner_azure.png">-->
<!--        </el-col>-->
<!--      </el-col>-->
<!--    </el-col>-->
    </el-col>


  </el-row>

  </div>
</template>
<script>

import announcement from "@/compoents/home/announcement";

export default {
  name: "home",
  data() {
    return {
      list: [
          '【迭代】文档列表列头调整样式',
          '【迭代】增加隐藏的开关是否让admin看见部门文档',
          '【迭代】文档系统用户管理界面增加提示默认密码',
          '【迭代】修改组织架构树的获取方式，不依赖部门文档'
      ],
      isDisplay:true,
        bannerTexts: [
             '伊登文档安全管理系统，高效、便捷、安全、协同、合规，专注文档管控和内容管理！为企业提供统一文档办公平台，为企业业务系统提供文档内容服务。',
             '针对exchange邮箱以及Outlook客户端开发的邮件大附件解决方案，为企业提供高效、便捷、安全、经济、可控的大附件存储、传输与集中存储。',
            '伊登多云管理平台是提供对公有云、私有云以及混合云统一集成管理的平台。其为客户提供资源的管理、服务的监控、以及费用的比对。云管理平台能够把现如今业届主流云厂商的平台兼容，客户可一点操作多个不同云平台的资源，使得企业客户不在受管理方面的困扰。',
            'eBPM审批管理系统是一套基于云端的业务流程构建和管理平台，为用户提供灵活的拖拽式设计界面，帮助企业无代码化快速构建企业内部业务流程，实现跨部门的高效业务流程审批和管理, 为不同企业发展阶段提供不同的流程自动化支撑。',
            '伊登ITSM工单管理系统 是一套将工作流程标准化，实现服务管理的协同性、便捷性和高效率的工单管理平台。',
        ],
        bannerText: '',
    }
  },
  components: {
    announcement
  },
  mounted() {
    this.$store.commit('setCheckIndex', 4);
    this.bannerText = this.bannerTexts[0];
  },
  methods: {
      carouselChange() {
          let idx = this.$refs.carousel.activeIndex;
          this.bannerText = this.bannerTexts[idx];
      },
    goPage(index, path) {
      this.$store.commit('setCheckIndex', index);
      // this.$store.state.common.checkIndex = index;
      this.$router.push({path: path});
    },
    arrowClick(val) {
      if(val == 'next') {
        this.$refs.carousel.next();
        return;
      }
      this.$refs.carousel.prev();
    },
    productDoc() {
      this.$store.commit('setCheckIndex', 2);
      // this.$store.state.common.checkIndex = 2;
      this.$router.push({path: '/DocSecurity'});
    },
    productAsset() {
      this.$store.commit("setCheckIndex", 2);
      this.$router.push({path: '/assets'})
    },
    productMail() {
      this.$store.commit('setCheckIndex', 2);
      this.$router.push({path: '/mailAttachment'})
    },
      //多云管理平台
      productCloud() {
          this.$router.push('cloudManage')
      },
      //eBPM
      productBPM() {
          this.$router.push('productBPM')
      },
      //ITSM
      productITSM() {
          this.$router.push('productITSM')
      },
    open(){
      /*if(this.isDisplay) {
        this.$refs.rowhome.$el.style.display = 'none';
        this.$refs.rowmenu.$el.style.display = 'block';
        this.isDisplay = false;
      }else{
        this.$refs.rowhome.$el.style.display = 'block';
        this.$refs.rowmenu.$el.style.display = 'none';
        this.isDisplay = true;
      }*/
    }
  }
}
</script>

<style lang="less" scoped>

 /* @media screen and (max-width: 1919px){
      .new-information .new-list {
          max-width: 800px;
          width: 100%;
      }

      .new-information .new-list .new-item {
          margin-top: 20px;
      }

      .new-information .new-list .new-item .news {
          margin-right: 10px;
      }
  }

  @media screen and (max-width: 1920px){
      .partner .partner-list {
          max-width: 530px;
          width: 100%;
          margin: auto;
          float: unset;
      }

      .partner-item {
          margin-top: 20px;
      }
  }*/
 #app {
   overflow: hidden;
 }
img{
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

 .banner {
   background: url("../../img/home/home_banner.png") no-repeat top center;
   //width: 99vw;
     width: 100%;
   height: 920px;


   .banner-des {
     font-size: 32px;
       font-weight: 600;
       color: #FFFFFF;
     line-height: 59px;
     padding-top: 45px;
   }

   .banner-title {
     font-size: 30px;
     font-weight: 500;
     color: #FFFFFF;
     line-height: 56px;
   }

   .banner-text {
     text-align: center;
   }

   .banner-carousel {
     text-align: center;
     .carousel {
       width: 100%;
       display: inline-block;

       .carousel-img {
         //width: 100%;
         //height: 450px;
       }

       .product-title {
         font-weight: 500;
         color: #FFFFFF;
         line-height: 56px;
         margin-bottom: 20px;
       }

       .product-des {
         width: 542px;
         height: 48px;
         font-size: 14px;
         font-weight: 400;
         color: #FFFFFF;
         line-height: 24px;
         position: relative;
         bottom: 45px;
         left: 52px;
         display: none;
       }

       .banner-right-arrow {
         width: 27px;
         margin-left: 8px;
         padding-bottom: 3px;
       }

       .banner-btn {
         position: relative;
         bottom: 126px;
         width: 192px;
         height: 44px;
         background: #3764D7;
         border-radius: 20px;
         font-size: 20px;
         font-weight: 500;
         color: #FFFFFF;
         line-height: 20px;
         display: none;
       }

       .is-active .product-title {
         font-size: 30px;
       }

       .is-active .banner-btn {
         display: unset;
       }

       .is-active  .product-des {
         display: unset;
       }
     }

     .switch-btn:hover {
       cursor: pointer;
     }

     .switch-btn {
       width: 13%;
       padding-top: 250px;
       height: 450px;
     }

     .left {
       margin-right: 50px;
       text-align: right;

     }
     .right {
       text-align: left;
       margin-left: 50px;
     }
   }

   .banner-notice {
     margin-top: 72px;
     .notice {
       height: 42px;
       //background: rgba(255, 255, 255, 0.2);
       //box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.5);
       //border-radius: 27px;
         .home-title {
             font-size: 16px;
             font-weight: 400;
             color: #FFFFFF;
             text-align: center;
         }

       .notice-icon {
         background-color: rgba(255, 255, 255, 0.6);
         border-radius: 27px;
         height: 42px;
         background-image: url("../../img/common/ic_tongzhi.svg");
         background-repeat: no-repeat;
         background-position-y: center;
         background-position-x: center;
         margin-right: 36px;
       }
     }
   }
 }

 .new-information {
   display: none;
   margin-top: 120px;
   .new-list {
     margin: auto;
     float: unset;
     margin-top: 48px;


     .new-item:hover {
       cursor: pointer;

       .new-img {
         img {
           transform: scale(1.2) !important;
         }
       }
     }
     .new-item {
       text-align: left;
       margin-top: 20px;

       .new-img {
         img {
           transition: all .5s;
         }
       }

       .news {
         width: 384px;
       }

       .new-title {
         margin-top: 24px;
         margin-bottom: 16px;
         font-size: 18px;
         font-weight: 500;
         color: #000000;
         line-height: 24px;
       }

       .new-des {
         font-size: 14px;
         font-weight: 400;
         color: #414141;
         line-height: 24px;
         text-align: justify;
         height: 48px;
         overflow: hidden;
         white-space: normal;
         overflow: hidden;
         -webkit-line-clamp: 2;
         text-overflow: ellipsis;
         display: -webkit-box;
         -webkit-box-orient: vertical;
       }

       .new-link {
         height: 24px;
         vertical-align: top;
         margin-top: 16px;
         .new-detail {
           height: 24px;
           font-size: 14px;
           font-weight: 400;
           color: #3764D7;
           line-height: 24px;
           vertical-align: top;
           margin-right: 8px;
         }
       }
     }
   }
 }

 .partner {
   margin-top: 120px;
   margin-bottom: 88px;

   .partner-list {
     margin-top: 48px;
     margin-bottom: 89px;
   }
 }

 .title {
   font-size: 40px;
   font-weight: 500;
   color: #3764D7;
   line-height: 48px;
   text-align: center;
 }

 /deep/  .el-carousel__mask {
   background-color: unset;
 }

 /deep/ .el-carousel__container {
   height: 500px;
 }

 /deep/ .el-carousel__item--card.is-in-stage {
   opacity: 0.4;
 }

 /deep/ .el-carousel__item--card.is-active {
   opacity: unset;
 }

 /*/deep/ .el-carousel__item--card {
   width: 80%;
 }*/

 @media screen and (max-width: 1919px){
   .new-information .new-list {
     max-width: 800px;
     width: 100%;
   }

   .new-information .new-list .new-item {
     margin-top: 20px;
   }

   .new-information .new-list .new-item .news {
     margin-right: 10px;
   }
 }

 @media screen and (max-width: 1920px){
   .partner .partner-list {
     max-width: 530px;
     width: 100%;
     margin: auto;
     float: unset;
   }

   .partner-item {
     margin-top: 20px;
   }
 }

 @media screen and (min-width: 1920px){
   .partner .partner-list {
     max-width: 1050px;
     width: 100%;
     margin: auto;
     float: unset;
   }

   .partner-item {
     margin-top: 20px;
     margin-bottom: 20px;
   }
 }



 @media screen and (max-width: 800px){
   .new-information .new-list {
     max-width: 370px;
     width: 100%;
   }

   .new-information .new-list .new-item {
     margin-top: 20px;
   }

   .new-information .new-list .new-item .news {
     margin-right: 10px;
   }
 }

 @media screen and (max-width: 768px){
   .partner .partner-list {
     max-width: 246px;
     width: 100%;
     margin: auto;
     float: unset;
   }

   .partner-item {
     margin-top: 20px;
     margin-left:5px;
   }
   .partner-item {
     img{
       width: 100%;
     }
   }
 }

 /* 1. 超小设备（小于576px）布局容器的宽度为100% */
 @media screen and (max-width: 767px) {

   /deep/ .el-carousel__indicators--outside{
     position:absolute
   }

   /deep/ .el-carousel__item--card {
     width: 100%;
     height:66%
   }

   .banner {
     background: url("../../img/home/home_banner.png") no-repeat top center;
     width: 100vw;
     height: 900px;


     .banner-des {
       font-size: 32px;
         font-weight: 600;
         color: #FFFFFF;
       line-height: 59px;
       padding-top: 45px;
     }

     .banner-title {
       font-size: 30px;
       font-weight: 500;
       color: #FFFFFF;
       line-height: 56px;
     }

     .banner-text {
       text-align: center;
     }

     .banner-carousel {
       text-align: center;

       .carousel {
         width: 89%;
         display: inline-block;

         .carousel-img {
           width:100%;
           height:100%
         }

         .product-title {
           font-weight: 500;
           color: #3764d7;
           line-height: 56px;
           margin-bottom: 20px;
           position: absolute;
           top: 27%;
           left: 0px;
         }

         .product-des {
           width: 542px;
           height: 48px;
           font-size: 14px;
           font-weight: 400;
           color: #FFFFFF;
           line-height: 24px;
           position: relative;
           bottom: 45px;
           left: 52px;
           display: none;
         }

         .banner-right-arrow {
           width: 27px;
           margin-left: 8px;
           padding-bottom: 3px;
         }

         .banner-btn {
           position: relative;
           bottom: 138px;
           width: 145px;
           height: 44px;
           background: #3764D7;
           border-radius: 20px;
           font-size: 20px;
           font-weight: 500;
           color: #FFFFFF;
           line-height: 20px;
           display: none;
         }

         .is-active .product-title {
           font-size: 30px;
         }

         .is-active .banner-btn {
           display: unset;
         }

         .is-active .product-des {
           display: unset;
         }
       }

       .switch-btn:hover {
         cursor: pointer;
       }

       .switch-btn {
         padding-top: 250px;
         height: 450px;
       }

       .left {
         text-align: right;
       }

       .right {
         text-align: left;
       }
     }

     .banner-notice {
       margin-top: 72px;

       .notice {
         height: 42px;
         //background: rgba(255, 255, 255, 0.2);
         //box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.5);
         //border-radius: 27px;
         position: relative;
           .home-title {
               font-size: 16px;
               font-weight: 400;
               color: #FFFFFF;
               text-align: center;
           }

         .notice-icon {

           background-color: rgba(255, 255, 255, 0.6);
           border-radius: 27px;
           height: 42px;
           background-image: url("../../img/common/ic_tongzhi.svg");
           background-repeat: no-repeat;
           background-position-y: center;
           background-position-x: center;
           margin-right: 1px;
         }

         .text-container {
           position: absolute;
           top: 0px;
           right: 7%;
         }
       }
     }
   }

   .partner {
     margin-top: 60px;
     margin-bottom: 65px;

     .partner-list {
       margin-top: 48px;
       margin-bottom: 89px;
     }
   }
   .footer {
     width: 100%;
     background-color: #2D3142;

     .about-nocontent{
       height: 100px;
       border-bottom: 1px solid #FFFFFF;
       //padding-left: 606px;
       padding-top: 15px;

       .about-item {
         display: inline-block;
         font-size: 14px;
         font-weight: 400;
         color: #FFFFFF;
         line-height: 24px;
         //margin-left: 72px;
         text-align: center;
       }
       .about-item-product-right{
         display: inline-block;
         font-size: 14px;
         font-weight: 400;
         color: #b1b1b1;
         line-height: 24px;
         text-align: left;

       }

       .about-list:last-child {
         margin-top: 24px;
       }
       .about-list-pro-left{
         margin-top: 24px;
       }
       .about-list-pro-right{

       }

       .about-item:hover {
         color: #3764D7;
         cursor: pointer;
       }

       .about-item:first-child {
         margin-left: 0;
       }
     }
     .about {
       height: 125px;
       border-bottom: 1px solid #FFFFFF;
       //padding-left: 606px;
       padding-top: 30px;

       .about-item {
         display: inline-block;
         font-size: 14px;
         font-weight: 400;
         color: #FFFFFF;
         line-height: 24px;
         //margin-left: 72px;
         text-align: center;
       }
       .about-item-product-right{
         display: inline-block;
         font-size: 14px;
         font-weight: 400;
         color: #b1b1b1;
         line-height: 24px;
         text-align: left;

       }

       .about-list:last-child {
         margin-top: 24px;
       }
       .about-list-pro-left{
         margin-top: 24px;
       }
       .about-list-pro-right{

       }

       .about-item:hover {
         color: #3764D7;
         cursor: pointer;
       }

       .about-item:first-child {
         margin-left: 0;
       }
     }
   }
 }

 /* 3. 桌面显示器（大于等于768px）布局容器宽度为720px */
 @media screen and (min-width: 768px) {
 }

 /* 4. 大桌面显示器（大于等于992px）布局容器宽度为960px */
 @media screen and (min-width: 992px) {
 }

 /* 5. 超大桌面显示器（大于等于1200）布局容器宽度为1140 */
 @media screen and (min-width: 1200px) {
 }

</style>

